import styled from "@emotion/styled/macro";

const FooterContainer = styled.div`
  background-color: black;
  color: white;
  padding-top: clamp(50px, 5vw, 100px);
  padding-bottom: clamp(10px, 5vw, 50px);
  padding-left: clamp(20px, 5vw, 100px);
  padding-right: clamp(20px, 5vw, 100px);
`;

const QuestionTitle = styled.h3`
  font-family: Lora;
  font-size: calc(1rem + 3vw);
  font-weight: 200;
  padding-bottom: 10px;
  text-align: center;
`;

const ContactInformation = styled.p`
  font-family: Helvetica;
  font-weight: 400;
  padding-bottom: 30px;
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 800px) {
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 25px;
  }
`;

const BusinessName = styled.p`
  color: #26965c;
  font-family: Lora;
  font-size: calc(1rem + 1vw);
  font-weight: 200;
  margin-bottom: 0px;
`;

const Address = styled.p`
  font-family: Helvetica;
  font-size: calc(1rem + 0.5vw);
  font-weight: 200;
  margin-bottom: 2px;
`;

const Email = styled.p`
  font-family: Helvetica;
  font-size: calc(0.8rem + 0.25vw);
  margin-bottom: 2px;
`;

const Phone = styled.p`
  margin-bottom: 2px;
`;

const Hours = styled.p`
  font-size: calc(1rem + 0.25vw);
  font-weight: bold;
  position: relative;
  width: min-content;
  margin-bottom: 10px;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: white;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const DailySchedule = styled.p`
  font-size: calc(0.8rem + 0.25vw);
  margin-bottom: 5px;
`;

const LinkedText = styled.a`
  color: white;
  cursor: pointer;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const BottomFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 50px;
  padding-bottom: 5px;
  @media (max-width: 800px) {
    display: grid;
    justify-items: center;
    justify-content: center;
    grid-template-rows: auto;
    grid-template-areas: "SocialIcon" "BottomLinks" "Copyright";
  }
`;

const BottomLinks = styled.p`
  margin-bottom: 0;
  margin-top: 10px;
  grid-area: BottomLinks;
`;

const SocialIcon = styled.a`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  grid-area: SocialIcon;
  img {
    -webkit-filter: invert(1);
    filter: invert(1);
    height: 20px;
    width: 20px;
  }
`;

const Copyright = styled.p`
  color: #26965c;
  font-family: Lora;
  margin-bottom: 0;
  grid-area: Copyright;
  padding-top: 3px;
  font-size: calc(0.8rem + 0.25vw);
`;

export default function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <FooterContainer>
      <QuestionTitle>Have Questions?</QuestionTitle>
      <ContactInformation>
        <LinkedText href="/services">Click Here</LinkedText> for Our Frequently
        Asked Questions or Call Us at 541-405-4172
      </ContactInformation>
      <FlexContainer>
        <div>
          <BusinessName>WHOLE BODY HEALTH</BusinessName>
          <Address>
            28 Airport Road
            <br />
            Lebanon, OR 97355
          </Address>
          <Email>frontdesk@wholebodyhealthlebanon.com</Email>
          <Phone>Phone: (541) 405-4172</Phone>
          <Phone>Fax: (541) 405-4086</Phone>
        </div>
        <div>
          <Hours>Hours</Hours>
          <DailySchedule>
            <BoldText>Monday</BoldText>: 9 AM - 12 PM | 2 PM - 6 PM
          </DailySchedule>
          <DailySchedule>
            <BoldText>Tuesday</BoldText>: 2 PM - 6 PM
          </DailySchedule>
          <DailySchedule>
            <BoldText>Wednesday</BoldText>: 9 AM - 12 PM | 2 PM - 6 PM
          </DailySchedule>
          <DailySchedule>
            <BoldText>Thursday</BoldText>: 9 AM - 12 PM | 2 PM - 6 PM
          </DailySchedule>
          <DailySchedule>
            <BoldText>Friday</BoldText>: 9:30 AM - 1 PM
          </DailySchedule>
        </div>
      </FlexContainer>
      <BottomFlexContainer>
        <BottomLinks>
          <LinkedText href="/home">HOME</LinkedText> |{" "}
          <LinkedText href="/about">ABOUT</LinkedText> |{" "}
          <LinkedText href="/services">SERVICES</LinkedText> |{" "}
          <LinkedText href="/contact">CONTACT</LinkedText>
        </BottomLinks>
        <div>
          <SocialIcon href="https://www.facebook.com/people/Whole-Body-Health-Lebanon/100089215748528/">
            <img
              src={`${process.env.PUBLIC_URL}/images/facebook_logo.svg`}
              alt="Facebook"
            />
          </SocialIcon>
          <SocialIcon href="https://www.linkedin.com/company/whole-body-health-llc/">
            <img
              src={`${process.env.PUBLIC_URL}/images/linkedin_logo.svg`}
              alt="LinkedIn"
            />
          </SocialIcon>
        </div>
        <Copyright>©WHOLE BODY HEALTH LLC {currentYear} | LEGAL</Copyright>
      </BottomFlexContainer>
    </FooterContainer>
  );
}
